<template>
    <div class="inventory-wrap common-tab">
      <div class="bottom">

            <div class="materials-info">
              <div v-show="searchFormList">
                <SearchForm
                  ref="searchForm"
                  :form-list-extend="searchFormList"
                  :form-data="searchFormData"
                  :searchType='1'
                  :default-form="defaultForm"
                  @search="searchTable"
                />
              </div>
              <div class="bottom">
                <FunctionHeader
                  ref="functionHeader"
                  v-model="functionHeader.searchVal"
                  :search-title="functionHeader.searchTitle"
                  :export-name="functionHeader.exportName"
                  :export-params="functionHeader.exportParams"
                  :columns="columns"
                  @search="$refs.mTable.setTableData(true)"
                >
                </FunctionHeader>
                <MTable
                  ref="mTable"
                  :columns="columns"
                  :height="height"
                  :set-data-method="getTableData"
                >

                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="isKeyMaterials" slot-scope="{ row }">{{ row.isKeyMaterials ? '是' : '否' }}</div>
                <div slot="isExemption" slot-scope="{ row }">{{ row.isExemption ? '是' : '否' }}</div>
                <div slot="isStrengthenInspection" slot-scope="{ row }">{{ row.isStrengthenInspection ? '是' : '否' }}</div>
                <div slot="settingVariable" slot-scope="{ row }">{{ row.settingVariable ? '是' : '否' }}</div>
                  <div slot="status" slot-scope="{ row }">
                    {{ receiveStatus[row.status].name }}
                  </div>
                </MTable>
              </div>
            </div>
      </div>

    </div>
  </template>
  
  <script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import {
    searchFormList
  } from './form-list'
  import {
    columns
  } from './columns'
  import api from '@/api/warehouse/warehouse-assign'
  
  export default {
    name: 'purchaseWarehouseTaskList',
    components: {
      FunctionHeader,
      SearchForm
    },
    data() {
      return {
        api,
        total:0,
        params: {
          radio: 'ReceivedMaterialsOrderItem'
        },
        receiveStatus:[
            {
              id:0,
              name:'待开始'
            },
            {
              id:1,
              name:'已完成'
            }
        ],
        functionHeader: {
          searchVal: '',
          exportName: '',
          searchTitle:  '请输入收料单号/采购单号/供应商',
          exportParams: {}
        },
        functionHeader2: {
          searchVal: ''
        },
        columns,
        searchFormList,
        searchFormData: {
        },
        searchFormatData: [],
        defaultForm: { status: 0 },
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 44
      }
    },
    watch: {
    },
    mounted() {
    },
    methods: {
      async getTableData(condition, callback) {
        const { page, search, order } = condition
        const data = Object.assign({}, ...this.searchFormatData )
        const searchCondition = {
          ...data,
          searchKeyword: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getWaitingInWarehouseMaterialsListPage({
          page: page.currentPage,
          limit: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      // 查询
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }
  
  .inventory-wrap {
    width: 100%;
    padding-top: 10px;
  
    .m-radio-group {
      margin-left: 10px;
    }
  
    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }
  </style>
  