<template>
  <div class="inventory-account-container">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable">
        <SelectMultipleMaterialsNew
          slot="materialsM"
          ref="multipleMaterials"
          @selectChange="
            (key, val) => {
              handleSelect('materialsId', val && val.id)
            }
          "
        />
      </SearchForm>
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入单号/关联单号/批次码/序列号"
        :tags="functionHeader.tags"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :import-name="functionHeader.importName"
        :show-export="!selectData.length ? true : false"
        style="margin-left: -10px"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="selectData.length">
          <el-button v-if="permission('Export')" size="small" type="primary" @click="allMore('batchExport')">批量导出
          </el-button>
          <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData"
              @selection-change="selectionChange">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="recordType" slot-scope="{ row }">{{ row.recordType ? '流出' : '流入' }}</div>
        <div slot="type" slot-scope="{ row }">{{ getType(row) }}</div>
        <el-button slot="billNumber" slot-scope="{ row }" type="text" @click="viewDetail(row)">{{
            row.billNumber
          }}
        </el-button>
        <div slot="warehouseArea" slot-scope="{ row }">{{ row.warehouseAreaName }} - {{
            row.warehouseLocationName
          }}
        </div>
      </MTable>
    </div>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import columns from './columns'
import { searchFormList, getFunctionList } from './form-list'
import api from '@/api/warehouse/inventory-account'
import materialsApi from '@/api/information/materials'
import maApi from '@/api/information/materials-attribute'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { inType, outType } from '@/config/options.config'
import userApi from '@/api/sets/user/user'
import warehouseApi from '@/api/information/warehouse/model'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { warehouseRecordTypeIn, warehouseRecordTypeOut } from '@/config/options.sales'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'InventoryAccount',
  components: { SelectMultipleMaterialsNew, SearchForm, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'OutInWarehouseBillRecord'
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        exportName: 'exportInventoryAccount',
        exportParams: {}
      },
      inOrOutTypeList: [inType, outType],
      inOrOutType: '',
      type: null,
      selectData: [],
      searchKeyword: true,
      extraParams: [],
      isPageLoaded: false,
      total: 0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  beforeMount() {
    getFunctionList({
      onRecordTypeChange: this.onRecordTypeChange
    })
  },
  activated() {
    if (!this.isPageLoaded) {
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
  },
  mounted() {
    this.isPageLoaded = true
    this.getUserList()
    this.getMaterialsTypeList()
    this.getWarehouseList()
  },
  methods: {
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    onRecordTypeChange(val) {
      if (val === 0) {
        this.searchFormList[2].children[0].options = warehouseRecordTypeIn
      } else if (val === 1) {
        this.searchFormList[2].children[0].options = warehouseRecordTypeOut
      } else {
        this.searchFormList[2].children[0].options = []
      }
      this.$refs.searchForm.setForm({
        type: ''
      })
    },
    allMore(command) {
      const functions = {
        batchExport: () => {
          this.batchExport()
        }
      }
      functions[command]()
    },
    async getUserList() {
      const res = await userApi.getUserList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.searchFormList[5].children[0].options = res
      }
    },
    // 获取物料类型
    async getMaterialsTypeList() {
      const res = await maApi.getMaterialsTypeList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.searchFormList[4].children[0].options = res
      }
    },
    // // 获取物料属性
    // async getMaterialsAttributeList() {
    //   const res = await maApi.getMaterialsAttributeList()
    //   if (res) {
    //     this.searchFormList[6].children[0].options = res
    //   }
    // },
    async getWarehouseList() {
      const res = await warehouseApi.getRepositoryModelList({
        searchVal: Encrypt(
          JSON.stringify([
            ...this.defaultSearch,
            {
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    getType(row) {
      let current
      if (row.recordType === 0) {
        current = warehouseRecordTypeIn.find(item => row.type === item.id)
      } else {
        current = warehouseRecordTypeOut.find(item => row.type === item.id)
      }
      return current && current.name
    },
    selectionChange(data) {
      this.selectData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    querySearch(queryString, cb) {
      const restaurants = this.inOrOutTypeList[this.type]
      const results = queryString ? restaurants.filter(item => item.name.indexOf(queryString) !== -1) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect(key, val) {
      console.log(val)
      this.extraParams = this.extraParams.filter(item => item.fieldName !== key)
      if (val || val === 0) {
        this.extraParams.push({
          id: getUUid(),
          fieldName: key,
          fieldType: 'number',
          fieldValue: val,
          operator: 'eq'
        })
      }
      if (key === 'recordType') {
        this.inOrOutType = ''
        this.extraParams = this.extraParams.filter(item => item.fieldName !== 'type')
      }
      this.$refs.mTable.setTableData(true)
    },
    searchTable(data, type) {
      console.log(type)
      if (type === 1) {
        this.inOrOutType = null
        this.type = null
        this.$refs.multipleMaterials.keyword = ''
        this.extraParams = []
      }
      if (data && data.length > 0) {
        this.searchFormatData = data.filter(item => item.fieldName !== 'keyword')
      } else {
        this.searchFormatData = data
      }
      this.$refs.mTable.setTableData(true)
    },
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'warehouseOperateDetail',
        query: { number: row.billNumber, type: 'view', isOperate: 1 }
      })
      // if (row.directionType >= 0 && row.directionType !== null) {
      //   this.$router.push({
      //     name: 'ReturnAssignDetail',
      //     query: { type: 'view', id: row.taskId }
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'warehouseOperateDetail',
      //     query: { number: row.billNumber, type: 'view', isOperate: 1 }
      //   })
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.inventory-account-container {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}

::v-deep .customer-content div {
  width: 100%;
}

::v-deep .customer-content2 div:first-child {
  display: block !important;
}

.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}

::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
}

::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }
}
</style>
