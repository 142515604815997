import { receiveStatus, receiveInspectionStatus } from '@/config/options.config'

  export const searchFormListDetail = [
    {
      colSpan: 6,
      key: 'status',
      tagName: 'el-select',
      props: {
        placeholder: '请选择收料状态'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: receiveStatus
        }
      ]
    },
    {
      colSpan: 6,
      key: 'finishTimeStart',
      tagName: 'el-date-picker',
      props: {
        rangeSeparator: '至',
        startPlaceholder: '收料开始时间',
        endPlaceholder: '结束时间',
        align: 'center',
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm',
        defaultTime: ['00:00:00', '23:59:59']
      }
    },
    {
      colSpan: 6,
      key: 'creatorId',
      tagName: 'el-select',
      props: {
        placeholder: '请输入收料人'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'userFullName',
            value: 'id'
          },
          options: []
        }
      ]
    },
    {
      colSpan: 6,
      key: 'materials',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '请输入物料编码/名称/规格'
      },
      children: []
    },
    {
        colSpan: 6,
        key: 'batchNumber',
        tagName: 'el-input',
        defaultValue: 1,
        attrs: {
          placeholder: '请输入批次码'
        },
        children: []
      },
    {
      colSpan: 6,
      key: 'sequenceNumber',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '请输入序列号'
      },
      children: []
    },
    {
        colSpan: 6,
        key: 'checkStatus',
        tagName: 'el-select',
        props: {
          placeholder: '请选择检验状态'
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: receiveInspectionStatus
          }
        ]
      }
  ]

  
  