export const columns = [
    {
      prop: 'index',
      label: '序号',
      sortable: false,
      slotName: 'index',
      hiddenSearch: true,
      width: 60
    },
    {
      prop: 'code',
      label: '任务单号',
      hiddenSearch: true,
      sortable: false,
      minWidth: 180,
      index: 1
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      minWidth: 180,
      index: 1
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false,
      minWidth: 180,
      index: 2
    },
    {
      prop: 'specifications',
      label: '物料规格',
      hiddenSearch: true,
      sortable: false,
      minWidth: 180
    },
    {
      prop: 'isKeyMaterials',
      label: '是否关键件',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100,
      slotName:'isKeyMaterials'
    },
    {
      prop: 'isExemption',
      label: '是否免检',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100,
      slotName:'isExemption'
    },
    {
      prop: 'isStrengthenInspection',
      label: '是否加强检',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100,
      slotName:'isStrengthenInspection'
    },
    {
      prop: 'batchNumber',
      label: '批次码',
      hiddenSearch: true,
      sortable: false,
      minWidth: 200
    },
    {
      prop: 'quantity',
      label: '批次数量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 120
    },
    {
      prop: 'sequenceNumber',
      label: '序列号',
      hiddenSearch: true,
      sortable: false,
      minWidth: 200
    },
    {
      prop: 'supplierName',
      label: '供应商',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'purchaseOrderCode',
      label: '采购单号',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsReceivedOrderCode',
      label: '收料单号',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'settingVariable',
      label: '是否设变件',
      hiddenSearch: true,
      sortable: false,
      slotName:'settingVariable',
      minWidth: 100
    },
    {
      prop: 'source',
      label: '来源',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
      prop: 'sourceCode',
      label: '来源单号',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'status',
      label: '状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      minWidth: 100
    },
    {
      prop: 'remark',
      label: '备注',
      hiddenSearch: true,
      sortable: false
    }
  ]