  export const searchFormList = [
    {
      colSpan: 5,
      key: 'materials',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '请输入物料编码/名称/规格'
      },
      children: []
    },
    {
        colSpan:5,
        key: 'batchNumber',
        tagName: 'el-input',
        attrs: {
          placeholder: '请输入批次码'
        },
        children: []
      },
    {
      colSpan: 5,
      key: 'sequenceNumber',
      tagName: 'el-input',
      attrs: {
        placeholder: '请输入序列号'
      },
      children: []
    },
    {
      colSpan: 5,
      key: 'status',
      tagName: 'el-select',
      props: {
        placeholder: '请选择状态'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: [
            {
              id:0,
              name:'待开始'
            },
            {
              id:1,
              name:'已完成'
            }
        ]
        }
      ]
    }
  ]

  
  