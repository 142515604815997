export const columnsDetail = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  {
    prop: 'specifications',
    label: '物料规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'receivedQuantity',
    label: '收料数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'returnedQuantity',
    label: '退料数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'creator',
    label: '收料人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '收料时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'supplierName',
    label: '供应商',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'purchaseOrderCode',
    label: '采购单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'code',
    label: '收料单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'settingVariable',
    label: '是否设变件',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status'
  },
  {
    prop: 'checkStatus',
    label: '检验状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'checkStatus'
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 240
  }
]